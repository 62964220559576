import React, { useState } from "react"
import { Link } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import "bulma/css/bulma.css"
import "../styles/megamenu.scss"
const _megamenu = props => {
  const [openedDrop, setOpenedDrop] = useState(true)
  const { val, tytuly, podkategorie, isUrzadzenia, burgerActive } = props
  const handleOnclick = () => {
    setOpenedDrop(!openedDrop)
  }
  const tytulySorted = tytuly.sort((a, b) => a.node.tytul.localeCompare(b.node.tytul))
  return (
    <div className="navbar-item has-dropdown is-hoverable is-mega">
      <div className="navbar-link el-menu-top" onClick={handleOnclick}>
        {val.nazwaMenu}
      </div>
      <div
        id="offerDropdown"
        className={`navbar-dropdown ${burgerActive && openedDrop ? "is-hidden-mobile is-hidden-tablet" : ""}`}
      >
        <div className="container is-fluid">
          {!isUrzadzenia && (
            <div className="columns is-gapless is-multiline">
              {tytuly &&
                tytulySorted.map(t => {
                  if (t.node.slug !== "urządzenia" && !isUrzadzenia) {
                    return (
                      <div key={uuidv4()} className="column is-one-quarter">
                        <Link to={`/${t.node.slug}`}>
                          <div className="title is-6 is-mega-menu-title">{t.node.tytul}</div>
                        </Link>
                        {podkategorie
                          .filter(pdk => pdk.node.kategoria === t.node.slug)
                          .map(el => (
                            <a key={uuidv4()} href={`/${el.node.slug}`} className="navbar-item">
                              <div className="navbar-content link-podkategorie">
                                <Link style={{ color: "#211019" }} to={`/${el.node.slug}`}>
                                  {el.node.tytul}
                                </Link>
                              </div>
                            </a>
                          ))}
                      </div>
                    )
                  }
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default _megamenu
