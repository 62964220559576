import React, { useState } from "react"
import "bulma/css/bulma.css"
import "../styles/navigation.scss"
import logo from "../assets/logo.png"
import { graphql, Link, useStaticQuery } from "gatsby"
import _megamenu from "./_megamenu"
import { v4 as uuidv4 } from 'uuid';

const _navigation = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulStronaPodkategoria {
        edges {
          node {
            tytul
            slug
            kategoria
            zdjecieDlaUrzadzen {
              fixed {
                src
              }
            }
          }
        }
      }
      allContentfulStronaKategoriaGlowna {
        edges {
          node {
            tytul
            slug
          }
        }
      }
      allContentfulNawigacja {
        edges {
          node {
            nazwaMenu
            slug
            kolejnosc
          }
        }
      }
    }
  `)

  const navContent = data.allContentfulNawigacja.edges.map(el => el.node)
  const navContentSorted = navContent.sort((a, b) => a.kolejnosc - b.kolejnosc)
  const [isActive, setIsActive] = useState(false)
  const tytuly = data.allContentfulStronaKategoriaGlowna.edges
  const podkategorie = data.allContentfulStronaPodkategoria.edges

  const handleActiveOnClick = () => {
    setIsActive(!isActive)
  }

  return (
    <React.Fragment>
      <Link to={"/"}>
        <div className="logo-container-main">
          <img src={logo} className="logo" alt="laser-medica" />
        </div>
      </Link>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a
            role="button"
            className={`navbar-burger ${isActive ? "is-active" : ""}`}
            onClick={handleActiveOnClick}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>

        <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
          <div
            className="navbar-start navbar-kreska"
            style={{ margin: "0 auto", minHeight: "60px" }}
          >
            {navContentSorted.map((val, id) => {
              return val.slug === "oferta" ? (
                <_megamenu
                  burgerActive={isActive}
                  key={uuidv4()}
                  val={val}
                  podkategorie={podkategorie}
                  tytuly={tytuly}
                  isUrzadzenia={false}
                />
              ): (
                <Link
                  activeClassName="active"
                  key={uuidv4()}
                  style={{ textDecoration: "none" }}
                  className="navbar-item el-menu-top"
                  to={`/${val.slug}`}
                >
                  {val.nazwaMenu}
                </Link>
              )
            })}
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}
export default _navigation
